import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import TotalLevelUser from "./TotalLevelUser";
import TotalLevelStaked from "./TotalLevelStaked";
import { FaLock } from "react-icons/fa6";
import { useAccount, useChainId } from "wagmi";
import { APIinstance } from "../../../axios.config";
import Skeleton from "react-loading-skeleton";
import unloack from "../../../assets/new_ui/Unlock.svg";
import lock from "../../../assets/new_ui/Lock.svg";
import "../levelDe.css";

function LevelCount({ index, item, refresh, totalUsers, totalStaked }) {
  const { address, isConnected } = useAccount();

  return (
    <div className="">
      <NavLink to={`/levelDetails/${item.level}`}>
        <div className="d-flex justify-content-center mx-auto">
          <div className="wrapper clash">
            <div className="clash-card barbarian">
              <div className="clash-card__image clash-card__image--barbarian">
                {item.status === true ? (
                  <img
                    src={unloack}
                    className="img1"
                    style={{ height: "45px" }}
                  />
                ) : (
                  <img
                    className="img1"
                    src={lock}
                    style={{ height: "45px" }}
                    alt=""
                  />
                )}
                <img src={item.image} alt="barbarian" className="img2" />
              </div>
              <div className="clash-card__level d-flex mx-auto justify-content-center align-items-center clash-card__level--barbarian bg_level text-dark p-3 mb-0">
                Level {item.level}
                {/* <FaLock className="fs-5"/> */}
              </div>
              {/* <div className="clash-card__unit-name">The Barbarian</div> */}
              <div className="clash-card__unit-stats clash-card__unit-stats--barbarian  d-lg-flex d-block justify-content-around ">
                <div className="one-third">
                  <div className="stat">
                    {" "}
                    {/* <TotalLevelUser
                      index={index}
                      totalUsers={totalUsers}
                      refresh={refresh}
                    /> */}
                    {typeof item?.totalUsers === "number" ? (
                      item?.totalUsers
                    ) : (
                      <Skeleton count={1} />
                    )}
                    
                  </div>
                  <div className="stat-value">Member</div>
                </div>
                <div className="one-third no-border">
                  <div className="stat">
                    {/* <TotalLevelStaked
                      index={index}
                      totalStaked={totalStaked}
                      refresh={refresh}
                    /> */}
                    {typeof item?.totalStaked === "number" ? (
                      item?.totalStaked?.toFixed(2)
                    ) : (
                      <Skeleton count={1} />
                    )}
                  </div>
                  <div className="stat-value">Stake</div>
                </div>

                <div className="one-third no-border">
                  <div className="stat">
                    {/* <TotalLevelStaked
                      index={index}
                      totalStaked={totalStaked}
                      refresh={refresh}
                    /> */}
                    {typeof item?.totalStaked === "number" ? (
                      item?.activeCoins?.toFixed(2)
                    ) : (
                      <Skeleton count={1} />
                    )}
                  </div>
                  <div className="stat-value">Active</div>
                </div>


                <div className="one-third no-border">
                  <div className="stat">
                    {/* <TotalLevelStaked
                      index={index}
                      totalStaked={totalStaked}
                      refresh={refresh}
                    /> */}
                    {typeof item?.totalStaked === "number" ? (
                      item?.activeCoinsPercent?.toFixed(2)
                    ) : (
                      <Skeleton count={1} />
                    )}
                  </div>
                  <div className="stat-value">Required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default LevelCount;

import axios from "axios";
const GIVEAWAY_BASE_URL = "https://giveway.vrcscan.com/api";
const BASE_URL = "https://allv20.vrcscan.com/api"
// const Chat_BASE_URL = "https://sdwqxgxs-3300.inc1.devtunnels.ms"
const Chat_BASE_URL = "https://chatbe.vrcscan.com"


// const BASE_URL = "http://192.168.100.119:3444/api"
// const BASE_URL = "http://192.168.100.119:3444/api/"


export const APIinstance = axios.create({
    baseURL: `${BASE_URL}`
})

export const GiveAwayAPIinstance = axios.create({
    baseURL: `${GIVEAWAY_BASE_URL}`
})

export const UserRegisterChat = axios.create({
    baseURL: `${Chat_BASE_URL}`
})
import React, { useEffect, useState } from "react";
import { useAccount, useChainId } from "wagmi";
import { readContract } from "../../../instances/instance";
import { defaultChain } from "../../../config";
import CountUp from "react-countup";
import mem2 from "../../../assets/new_ui/Members 2 1.svg";
import mem2Hover from "../../../assets/new_ui/Members Black 1.svg";
import mem3 from "../../../assets/new_ui/Referral 3 (1).svg";
import mem4 from "../../../assets/new_ui/total Staking 1.svg";
import mem4Hover from "../../../assets/new_ui/total Staking  Black 2.svg";
import mem3Hover from "../../../assets/new_ui/Referral 3 (1).svg";
import teamstake from "../../../assets/new_ui/Members 2 1.svg";
import teamstakehover from "../../../assets/new_ui/Members Black 1.svg";

import { formatEther } from "viem";
import { APIinstance } from "../../../axios.config";
function UserDetails({userDetailss, getActiveStake}) {
  // console.log("userDetailss",userDetailss.teamNum);
  const { address, isConnected } = useAccount();
  const chain = useChainId();
  // const [userDetails, setUserDetails] = useState({
  //   teamNum: null,
  //   directsNum: null,
  //   totalDeposit: null,
  //   teamTotalDeposit: null,
  // });
 
  // const getUserDetail = async () =>{
  //   try {
  //     const data = await APIinstance.get(`withdraw/userStats/${address}`);
  //     // console.log("datasdas",data.data);
  //     setUserDetails(data?.data);
  //   } catch (error) {
  //     console.error("error while get total users", error);
  //   }
  // }
  // const getUserDetails = async () => {
  //   try {
  //     const contractInstance = readContract();
  //     const { teamNum, directsNum, totalDeposit, teamTotalDeposit } =
  //       await contractInstance.userInfo(address);
  //     {teamNum, directsNum, totalDeposit}
  //     setUserDetails({
  //       teamNum: Number(teamNum),
  //       directsNum: Number(directsNum),
  //       totalDeposit: formatEther(totalDeposit),
  //       teamTotalDeposit: formatEther(teamTotalDeposit),
  //     });
  //   } catch (error) {
  //     console.error("error while get total users", error);
  //   }
  // };
  // useEffect(() => {
  //   if (isConnected && chain === defaultChain) getUserDetail();
  // }, [isConnected && chain, address]);

  const [hover, setHover] = useState({
    mem2: false,
    mem3: false,
    mem4: false,
    teamstake: false,
  });

  // Handler to toggle hover state
  const handleHover = (image) => {
    setHover((prevHover) => ({ ...prevHover, [image]: !prevHover[image] }));
  };
  return (
    <>
      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("mem2")}
        onMouseLeave={() => handleHover("mem2")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.mem2 ? mem2Hover : mem2}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              end={userDetailss?.teamNum}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
              Total Member
            </h2>
          </div>
        </a>
      </div>

      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("mem3")}
        onMouseLeave={() => handleHover("mem3")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.mem3 ? mem3Hover : mem3}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              end={userDetailss?.directsNum}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
              Direct Members
            </h2>
          </div>
        </a>
      </div>

      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("mem4")}
        onMouseLeave={() => handleHover("mem4")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.mem4 ? mem4Hover : mem4}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              end={userDetailss?.totalDeposit}
              // end={getActiveStake?.data?.userLevelActiveCoins}
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Self Stake
            </h2>
          </div>
        </a>
      </div>

      {/* <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("teamstake")}
        onMouseLeave={() => handleHover("teamstake")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.teamstake ? teamstakehover : teamstake}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              // end={userDetailss?.teamTotalDeposit}
              end={getActiveStake?.data?.stakeActiveCoin}
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Self Stake Required
            </h2>
          </div>
        </a>
      </div> */}

      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("teamstake")}
        onMouseLeave={() => handleHover("teamstake")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.teamstake ? teamstakehover : teamstake}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              end={userDetailss?.teamTotalDeposit}
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Total Team Stake
            </h2>
          </div>
        </a>
      </div>


      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("mem4")}
        onMouseLeave={() => handleHover("mem4")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.mem4 ? mem4Hover : mem4}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              // end={userDetailss?.totalDeposit}
              // end={getActiveStake?.data?.userLevelActiveCoins}
              end={userDetailss?.totalDeposit - (userDetailss.totalReward / 3)}
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Active Self Stake
            </h2>
          </div>
        </a>
      </div>


       <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("teamstake")}
        onMouseLeave={() => handleHover("teamstake")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.teamstake ? teamstakehover : teamstake}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              // end={userDetailss?.teamTotalDeposit}
              // end={getActiveStake?.data?.stakeActiveCoin}
              end={getActiveStake?.data?.stakeActiveCoin}

              // end={getActiveStake?.data?.userLevelActiveCoins}
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Self Stake Required
            </h2>
          </div>
        </a>
      </div>

      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("teamstake")}
        onMouseLeave={() => handleHover("teamstake")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.teamstake ? teamstakehover : teamstake}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              // end={userDetailss?.teamTotalDeposit}
              // end={getActiveStake?.data?.stakeActiveCoin}
              end = {
                Math.max(
                0,
                (getActiveStake?.data?.stakeActiveCoin) - (userDetailss?.totalDeposit - (userDetailss.totalReward / 3))
              )
            }
              
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Remaining  Stake Required
            </h2>
          </div>
        </a>
      </div>

      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("teamstake")}
        onMouseLeave={() => handleHover("teamstake")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.teamstake ? teamstakehover : teamstake}
                alt=""
              />
            </div>
            <CountUp
              className="text-center fs-2 fw-bold d-flex justify-content-center change"
              delay={2}
              // end={userDetailss?.teamTotalDeposit}
              // end={getActiveStake?.data?.stakeActiveCoin}
              end={getActiveStake?.data?.userLevelActiveCoins}
              decimals={4}
            />

            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
             Team Active Stake 
            </h2>
          </div>
        </a>
      </div>

    </>
  );
}

export default UserDetails;
